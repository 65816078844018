import './profile.css';
import SearchBar from '../../components/search-bar/search-bar.js';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import BackupIcon from '@mui/icons-material/Backup';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useMemo } from 'react';
import { useMembersList } from "../../api/members-list/use-list-member-list.js";
import { useChangePassword } from '../../api/members-list/use-change-password.js';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useEditMemberList } from "../../api/members-list/use-edit-member-list.js";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function Profile(props) {

  const [base64, setBase64] = useState('');
  const [IDbase64, setIDBase64] = useState('');
  const [userID, setUserID] = useState(-1);
  const [profileBase64, setProfileBase64] = useState('');

  const navigate = useNavigate(); // Get the navigate function
  const { data: userData } = useMembersList();
  const { mutate: updatePassword, isPending } = useChangePassword({
    callBackOnSuccess: () => {
      toast.success("updated password")
    }
  });

  const handleFileChange = (event) => {

    if (!userData || userData.length == 0) {
      toast.error("an error occured, please try again later")
    } else {
      setUserID(userData[0].id);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          setBase64(base64String);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleFileChange2 = (event) => {
    if (!userData || userData.length == 0) {
      toast.error("an error occured, please try again later")
    } else {
      setUserID(userData[0].id);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          setIDBase64(base64String);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleFileChange3 = (event) => {

    if (!userData || userData.length == 0) {
      toast.error("an error occured, please try again later")
    } else {
      setUserID(userData[0].id);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          setProfileBase64(base64String);
        };
        reader.readAsDataURL(file);
      }
    }
  };


  const { mutate: updateUser, isPending2 } = useEditMemberList({
    id: userID,
    callBackOnSuccess: () => {
      window.location.reload();
      toast.success("File uploaded")
      setIDBase64('');
      setBase64('');
      setProfileBase64('');
    }
  });
  return (
    <div className="main-content">

      <SearchBar userData={props.userData && props.userData.length > 0 ? props.userData : []} styleName="desktop-search" />
      <div className="main-overview">
        <div style={{ display: "flex", alignItems: "center", margin: "0.7rem 0rem" }}>
          <h1 className="title">Profile</h1>
        </div>
        <div className="cards one-column one-row">
          <div className="card">
            <div className="subtitle"> Info</div>
            <Box
              sx={{ width: 300, borderRadius: 'sm' }}>
              <AspectRatio objectFit="contain">
                {userData && userData.length > 0 && userData[0].profile.profile_picture ? (
                  <img
                    src={userData[0]?.profile.profile_picture}
                    alt="Profile picture"
                  />
                ) : (
                  <p>No profile picture available</p>
                )}


              </AspectRatio>
            </Box>

            <div className="two-col-grid">
              <div className="smaller-blue-subtitle">Username:</div>
              <div>{userData ? userData[0].username : ""}</div>
              <div className="smaller-blue-subtitle">E-mail:</div>
              <div>{userData ? userData[0].email : ""}</div>

              <div className="smaller-blue-subtitle">Mobile number:</div>
              <div>{userData ? userData[0].profile.phone_number : ""}</div>
              {/* <div className="smaller-blue-subtitle">Smart contract pdf:</div>
                        <Button
                        component="label"
                        role={undefined}
                        variant="text"
                        sx={{textTransform:"none",fontSize:"1rem",fontFamily:"Sans",padding:"0rem 0.7rem"}}
                        tabIndex={-1}
                        startIcon={<CloudDownloadIcon/>}
                        >
                        Download file
                        <VisuallyHiddenInput type="file" />
                        </Button> */}
              <div></div>
              <Button
                component="label"
                variant="text"
                sx={{ textTransform: "none", fontSize: "1rem", fontFamily: "Sans", padding: "0rem 0.7rem" }}
                tabIndex={-1}
                startIcon={<KeyIcon />}
                onClick={() => updatePassword({ "email": userData[0]?.email })}
              >
                Request password change
              </Button>
            </div>
            <div className="subtitle"> Upload documents</div>

            <div className="two-col-grid">
              <div className="smaller-blue-subtitle">Passport:</div>
              <form onSubmit={(event) => {
                event.preventDefault();
                updateUser({
                  "profile": {
                    "passport_picture": base64
                  }
                })
              }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="text"
                  sx={{ textTransform: "none", fontSize: "1rem", fontFamily: "Sans", padding: "0rem 0.7rem" }}
                  tabIndex={-1}
                  startIcon={<BackupIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={handleFileChange} name="passport_picture" />
                </Button>
                <Button type="submit" disabled={base64 == '' ? true : false}>Submit</Button>

              </form>
              <div className="smaller-blue-subtitle">Profile picture:</div>
              <form onSubmit={(event) => {
                event.preventDefault();
                updateUser({
                  "profile": {
                    "profile_picture": profileBase64
                  }
                })
              }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="text"
                  sx={{ textTransform: "none", fontSize: "1rem", fontFamily: "Sans", padding: "0rem 0.7rem" }}
                  tabIndex={-1}
                  startIcon={<BackupIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={handleFileChange3} name="id_picture" />
                </Button>
                <Button disabled={profileBase64 == '' ? true : false} type="submit" >Submit</Button>

              </form>
              <div className="smaller-blue-subtitle">ID:</div>
              <form onSubmit={(event) => {
                event.preventDefault();
                updateUser({
                  "profile": {
                    "id_picture": IDbase64
                  }
                })
              }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="text"
                  sx={{ textTransform: "none", fontSize: "1rem", fontFamily: "Sans", padding: "0rem 0.7rem" }}
                  tabIndex={-1}
                  startIcon={<BackupIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={handleFileChange2} name="id_picture" />
                </Button>
                <Button type="submit" disabled={IDbase64 == '' ? true : false} >Submit</Button>

              </form>
            </div>
            <div>
              <Button variant="contained" color="error" onClick={() => {
                localStorage.clear();
                navigate("/sign-in")
              }}>
                Log out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;