import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useActivateUser = (first, second) => {
  return useReadData({
    queryKey: ["acticate-user"],
    endpoint: API.activateUser(first, second),
  });
};

export { useActivateUser };
