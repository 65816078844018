import "./statement.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Table from "@mui/joy/Table";
import React, { useState, useEffect } from "react";
import { useListTransactions } from "../../api/transactions/use-list-transactions.js";
import { Option, Select } from "@mui/joy";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DateTimeDisplay from "../../components/date-time-display.js";
function Statement(props) {
  const { data: statements } = useListTransactions();
  const [filterType, setFilterType] = React.useState("");
  const filteredStatements = statements?.filter((row) => {
    if (filterType !== "All") {
      const matchesStatus = row.trans_reason
        .toLowerCase()
        .includes(filterType.toLowerCase());
      return matchesStatus;
    }
    return true;
  });

  return (
    <div className="main-content">
      <SearchBar
        userData={
          props.userData && props.userData.length > 0 ? props.userData : []
        }
        styleName="desktop-search"
      />
      <div className="main-overview">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0.7rem 0rem",
            justifyContent: "space-between",
          }}
        >
          <h1 className="title">Statement</h1>
          <div>
            <Select
              style={{ height: "4rem", minWidth: "100px" }}
              placeholder="Filter"
              color="primary"
              variant="soft"
              onChange={(e) => {
                setFilterType(e.target.innerText);
              }}
            >
              <Option value="all">All</Option>
              <Option value="REGISTRATION">Registration</Option>
              <Option value="PAYOUT">Payout</Option>
              <Option value="INVESTMENT">Investment</Option>
              <Option value="COMMISSION">Commission</Option>
            </Select>
          </div>
        </div>
        <div className="cards one-column one-row">
          <div className="card">
            <div className="subtitle">Statement history</div>

            <Table
              aria-label="basic table"
              style={{
                overflowX: "auto",
                width: "100%",
                tableLayout: "auto",
              }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Transaction Reason</th>
                  <th>Transaction Type</th>
                  <th>Transaction mode</th>
                  <th>Amount USD</th>
                  <th>Amount BTC</th>
                  <th>Note</th>
                  <th>Status</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {filteredStatements?.map((row) => (
                  <tr key={row.pk}>
                    <td>{<DateTimeDisplay length={"short"} apiDateTime={row.trans_date} />}</td>
                    <td>{row.pk}</td>
                    <td>{row.trans_reason}</td>
                    <td>{row.trans_type}</td>
                    <td>{row.trans_mode}</td>
                    <td>{row.trans_amt_usd?.toFixed(2)}</td>
                    <td>{row.trans_amt_btc?.toFixed(5)}</td>
                    <td>{row.note}</td>
                    <td>{row.trans_status}</td>
                    <td>{row.trans_rejection_note}</td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statement;
