import * as React from 'react';
import './payout-modal.css';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions';
import { PropaneSharp } from '@mui/icons-material';
import { useAddTransactions } from '../../api/transactions/use-add-transaction';
import toast from 'react-hot-toast';
function PayoutModal(props) {

    const [openReinject1, setOpenReinject1] = React.useState(false);
    const [openReinject2, setOpenReinject2] = React.useState(false);
    const [openReinject3, setOpenReinject3] = React.useState(false);
    const [openBankPayment, setOpenBankPayment] = React.useState(false);
    const [successSnackbar, setsuccessSnackbar] = React.useState(false);
    const truncateToTwoDecimals = (num) => Math.trunc(num * 100) / 100;
    const [reinjectValue, setReinjectValue] = React.useState('');
    const handleReinjectChange = (event) => { setReinjectValue(event.target.value) };

    const validReinjectValue = () => {

        return (reinjectValue < props.value_btc && reinjectValue > 0);
    }
    const [exchangeRate, setExchangeRate] = React.useState("");

    React.useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch(
                    "https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT"
                );
                const data = await response.json();
                setExchangeRate(data.price); // Assuming `price` is the key in the response
            } catch (error) {
                console.error("Error fetching exchange rate:", error);
            }
        };

        fetchExchangeRate();
    }, []);
    const { mutate: addTransaction, isPending } = useAddTransactions({
        id: props.ID,
        callBackOnSuccess: () => {
            window.location.reload()
            toast.success("Payout recorded")
        }
    });
    return (
        <div className="button-left">

            <Button
                disabled={props.disabled}
                color="primary"
                variant="soft"
                size="lg"
                style={{ width: "-webkit-fill-available" }} onClick={() => setOpenReinject1(true)}>Payout</Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openReinject1}
                onClose={() => setOpenReinject1(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Payout
                    </Typography>
                    <Divider orientation="horizontal" />
                    <div style={{ fontSize: "20px", padding: "10px 0px" }}>
                        Available
                        <div style={{ fontSize: "25px", fontWeight: "700", textAlign: "center", padding: "10px" }}>{props.value_btc?.toFixed(5)} BTC</div>
                        Amount to payout
                    </div>

                    <Stack spacing={1.5}>
                        <Input
                            style={{ width: "auto" }}
                            value={reinjectValue}
                            onChange={handleReinjectChange}
                            placeholder="Enter amount"
                            type="number"
                            endDecorator={
                                <React.Fragment>
                                    <Divider orientation="vertical" />
                                    <div style={{ paddingLeft: "10px" }}>
                                        BTC
                                    </div>
                                </React.Fragment>
                            }
                            sx={{ width: 300 }}
                        />
                        <Typography color="primary">
                            {reinjectValue} BTC ≈{" "}
                            {truncateToTwoDecimals(exchangeRate * reinjectValue)} USD
                        </Typography>
                        {validReinjectValue() === false && <div><Alert variant="outlined" color="danger">Enter a valid amount.</Alert></div>}
                        <Button
                            size="lg"
                            disabled={!validReinjectValue()}
                            onClick={() => {
                                if (validReinjectValue()) {
                                    setOpenReinject1(false);
                                    addTransaction({
                                        "owner": props.ID,
                                        "trans_type": "DEBIT",
                                        "trans_mode": "CRYPTO",
                                        "trans_reason": "PAYOUT",
                                        "trans_amt_btc": -reinjectValue,
                                    })
                                }
                            }}
                        >Confirm</Button>

                    </Stack>
                </Sheet>
            </Modal>
            {/* 


      {/* SECOND MODAL*****************************************************************************/}
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openReinject2}
                onClose={() => setOpenReinject2(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Reinject
                    </Typography>
                    <Divider orientation="horizontal" />

                    <Typography
                        level="title-lg" variant="plain"
                    >
                        Are you sure you want to reinject
                        <div className="big-text-blue">

                            {typeof reinjectValue === "number"
                                ? `${reinjectValue.toFixed(5)} BTC`
                                : "Invalid value"}</div>
                        <TermsAndConditions />
                    </Typography>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyItems: "stretch", gap: "10px" }}>
                        <Button
                            size="lg"
                            onClick={() => {
                                setOpenReinject2(false);
                                setOpenReinject3(true);
                            }}
                        >Yes</Button>
                        <Button
                            size="lg"
                            color="neutral"
                            onClick={() => { setOpenReinject2(false); }}
                        >No</Button>
                    </div>
                </Sheet>
            </Modal>


        </div>

    );
};

export default PayoutModal;