import './deposit.css';
import SearchBar from '../../components/search-bar/search-bar.js';
import Table from '@mui/joy/Table';
import DepositModal from '../../components/deposit-modal/deposit-modal.js';
import DateTimeDisplay from '../../components/date-time-display.js';
import { useListDeposits } from '../../api/transactions/use-list-deposits.js';

function Deposit(props) {
    const { data: transactions } = useListDeposits();

    return (
        <div className="main-content">

            <SearchBar userData={props.userData && props.userData.length > 0 ? props.userData : []} styleName="desktop-search" />
            <div className="main-overview">
                <div style={{ display: "flex", alignItems: "center", margin: "0.7rem 0rem" }}>
                    <h1 className="title">Deposit</h1>
                    <DepositModal ID={props.userData && props.userData.length > 1 ? props.userData[0].id : -1} />
                </div>
                <div className="cards one-column one-row">
                    <div className="card">
                        <div className="subtitle">Deposit history</div>

                        <Table aria-label="basic table" style={{
                            overflowX: "auto",
                            width: "100%",
                            tableLayout: "auto"
                        }}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount USD</th>
                                    <th>Amount BTC</th>
                                    <th>Mode</th>
                                    <th>Reason</th>
                                    <th>Note</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions?.map((row) => (
                                    <tr key={row.pk}>
                                        <td><DateTimeDisplay apiDateTime={row.trans_date} /></td>
                                        <td>{Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(row.trans_amt_usd)} USD</td>
                                        <td>{row.trans_amt_btc?.toFixed(5)} BTC</td>
                                        <td>{row.trans_mode}</td>
                                        <td>{row.trans_reason}</td>
                                        <td>{row.note}</td>
                                        <td>{row.trans_status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Deposit;