import "./sign-up.css";
import Logo from "../../components/Logo/logo";
import { Alert, Button, Typography } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import React, { useState, useMemo } from "react";
import Select from "react-select";
import Option from "@mui/joy/Option";
import Input from "@mui/joy/Input";
import { Link } from "react-router-dom";
import TermsAndConditions from "../../components/terms-and-conditions/terms-and-conditions.js";
import countryList from "react-select-country-list";
import { useAddUser } from "../../api/users/use-add-user.js";
import MuiSelect from "@mui/joy/Select";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function bigOrEqual(num1, num2) {
  return num1 >= num2;
}
function equal(num1, num2) {
  return num1 === num2;
}
function SignUp(props) {
  const navigate = useNavigate(); // Get the navigate function
  const [count, setCount] = useState(1);
  const [passport, setPassport] = React.useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // validation variables

  const [fName, setFname] = useState(" ");
  const [lName, setLname] = useState(" ");
  const [email, setEmail] = useState("a@a.a");
  const [country, setCountry] = useState(" ");

  //  phone number

  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const [dob, setDOB] = useState(" ");
  const [isAdult, setIsAdult] = useState(null);

  const [username, setUsername] = useState(" ");
  const [password1, setPassword1] = useState(" ");
  const [password2, setPassword2] = useState(" ");

  const [emailExists, setEmailExists] = useState([]);
  const [usernameExists, setUsernameExists] = useState([]);

  const checkAge = () => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the birthday hasn't occurred this year yet
    const adjustedAge = monthDiff < 0 || (monthDiff === 0 && dayDiff < 0) ? age - 1 : age;

    setIsAdult(adjustedAge >= 18);
  };
  const handleEmailChange = async (e) => {
    setEmail(e.target.value);
    try {
      const response = await axios.get(
        `https://api.savoie.ai/api/users/userexists?email=${e.target.value}`
      );
      setEmailExists(response.data);
    } catch (error) { }
  };

  const handleUsernameChange = async (e) => {
    setUsernameExists(e.target.value);
    try {
      const response = await axios.get(
        `https://api.savoie.ai/api/users/userexists?username=${e.target.value}`
      );
      setUsernameExists(response.data);
    } catch (error) { }
  };

  function isValidEmail(email) {
    // Define a regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }
  const { mutate: addUser, isPending } = useAddUser({
    callBackOnSuccess: () => {
      toast.success("Request registered.");
      navigate("/sign-in");
    },
  });
  const [base64, setBase64] = useState("");
  const [IDbase64, setIDBase64] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileChange2 = (event) => {
    const file2 = event.target.files[0];
    if (file2) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setIDBase64(base64String);
      };
      reader.readAsDataURL(file2);
    }
  };

  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          {/* <Logo/> */}
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>Welcome!</div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
            Create an account
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            width: "fit-content",
            height: "auto",
            borderRadius: "8px",
            padding: "10px 10px",
          }}
        >
          <Stepper sx={{ width: "100%" }}>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 1) ? "solid" : "plain"}
                  color="primary"
                >
                  1
                </StepIndicator>
              }
            >
              Personal Info
            </Step>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 2) ? "solid" : "outlined"}
                  color={bigOrEqual(count, 2) ? "primary" : "neutral"}
                >
                  <span>2</span>
                </StepIndicator>
              }
            >
              Login Info
            </Step>
            <Step
              orientation="vertical"
              indicator={
                <StepIndicator
                  variant={bigOrEqual(count, 3) ? "solid" : "outlined"}
                  color={bigOrEqual(count, 3) ? "primary" : "neutral"}
                >
                  <span>3</span>
                </StepIndicator>
              }
            >
              Documents
            </Step>
          </Stepper>
          <div>
            <div className="sign-up-form">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.target);
                  formData.append("profile.phone_number", "+" + phone)
                  formData.append("profile.passport_picture", base64);
                  formData.append("profile.id_picture", IDbase64);
                  const requestData = {};
                  formData.forEach((value, key) => {
                    if (key.startsWith("profile.")) {
                      const profileKey = key.split(".")[1];
                      requestData.profile = {
                        ...requestData.profile,
                        [profileKey]: value,
                      };
                    } else {
                      requestData[key] = value;
                    }
                  });

                  addUser(requestData);
                }}
              >
                <div className={equal(count, 1) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    <div>First name</div>
                    <Input
                      className="black-descendents"
                      placeholder=""
                      variant="outlined"
                      name="first_name"
                      color={fName ? "neutral" : "danger"}
                      onChange={(e) => {
                        setFname(e.target.value);
                      }}
                    />
                    <div>Last name</div>
                    <Input
                      placeholder=""
                      name="last_name"
                      variant="outlined"
                      className="black-descendents"
                      color={lName ? "neutral" : "danger"}
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}
                    />
                    <div>Gender</div>
                    <MuiSelect
                      className="black-descendents"
                      defaultValue="MALE"
                      name="profile.gender"
                      variant="outlined"
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHERS">Other</Option>
                    </MuiSelect>
                    <div>E-mail</div>
                    {emailExists.length > 0 ? (
                      <p style={{ color: "#FF91A4" }}>
                        An account with this email already exists
                      </p>
                    ) : (
                      " "
                    )}
                    <Input
                      className="black-descendents"
                      placeholder=""
                      type="email"
                      name="email"
                      variant="outlined"
                      color={
                        email && isValidEmail(email) ? "neutral" : "danger"
                      }
                      onChange={(e) => {
                        setEmail(e);
                        handleEmailChange(e);
                      }}
                    />
                    <div>Country</div>
                    <Select
                      className="black-descendents"
                      options={options}
                      name="profile.country"
                      color={country ? "neutral" : "danger"}
                      onChange={(e) => {
                        setCountry(e.value);
                      }}
                    />
                    <div>Mobile number</div>

                    <PhoneInput
                      className="black-descendents"
                      inputStyle={{ width: "100%", fontSize: "1.2rem" }}
                      name="profile.phone_number"
                      country={country.toLowerCase()} // Set the default country
                      value={phone}  // The current phone number
                      onChange={handlePhoneChange} // Callback for changes
                      placeholder="Enter phone number"
                      color={phone ? "neutral" : "danger"}
                    />
                    <div>Date of birth</div>
                    {!isAdult ? (
                      <p style={{ color: "#FF91A4" }}>
                        User must be at least 18 years old
                      </p>
                    ) : (
                      " "
                    )}
                    <input
                      className="black-descendents like-mui-joy-input"
                      aria-label="Date"
                      type="date"
                      style={{ color: "black" }}
                      defaultValue={getTodayDate()}
                      name="profile.date_of_birth"
                      variant="outlined"
                      color={dob ? "neutral" : "danger"}
                      onChange={(e) => {
                        setDOB(e.target.value);
                        checkAge()
                      }}
                    />
                  </Stack>
                  <br></br>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>
                    <Button
                      disabled={
                        isAdult &&
                          fName.length > 2 &&
                          lName.length > 2 &&
                          email !== "a@a.a" &&
                          country !== " " &&
                          phone.length > 2 &&
                          dob.length > 2 &&
                          isValidEmail(email) &&
                          emailExists.length === 0
                          ? false
                          : true
                      }
                      onClick={() => setCount(2)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <div className={equal(count, 2) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    <div>Username</div>
                    {usernameExists.length > 0 ? (
                      <p style={{ color: "#FF91A4" }}>
                        An account with this username already exists
                      </p>
                    ) : (
                      " "
                    )}
                    <Input
                      placeholder=""
                      name="username"
                      className="black-descendents"
                      variant="outlined"
                      color={username ? "neutral" : "danger"}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        handleUsernameChange(e);
                      }}
                    />
                    <div>Password</div>
                    {password1.length < 8 && password1 !== " " ? (
                      <p style={{ color: "#FF91A4" }}>
                        password must contain at least 8 characters
                      </p>
                    ) : (
                      " "
                    )}
                    <Input
                      placeholder=""
                      type="password"
                      name="password"
                      className="black-descendents"
                      variant="outlined"
                      color={password1 ? "neutral" : "danger"}
                      onChange={(e) => {
                        setPassword1(e.target.value);
                      }}
                    />
                    <div>Confirm password</div>
                    <Input
                      placeholder=""
                      type="password"
                      className="black-descendents"
                      variant="outlined"
                      color={
                        password2 && password2 === password1
                          ? "neutral"
                          : "danger"
                      }
                      onChange={(e) => {
                        setPassword2(e.target.value);
                      }}
                    />
                  </Stack>

                  <br></br>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setCount(1)}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={
                        username.length > 2 &&
                          usernameExists.length === 0 &&
                          password1.length > 7 &&
                          password1 === password2
                          ? false
                          : true
                      }
                      onClick={() => setCount(3)}
                    >
                      Next
                    </Button>
                  </div>
                </div>

                <div className={equal(count, 3) ? "" : "hidden"}>
                  <Stack spacing={1} direction="column">
                    <div>Select ID copy (PNG, JPEG, and JPG supported)</div>

                    <input
                      type="file"
                      onChange={handleFileChange2}
                      name="id_picture"
                    />
                    <div>Select passport copy (PNG, JPEG, and JPG supported)</div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      name="passport_picture"
                    />
                  </Stack>
                  <br />
                  <TermsAndConditions text="submit" styleName="white-ref" />
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => setCount(2)}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      disabled={base64 && IDbase64 ? false : true}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>

              <br />
              <Link
                style={{ textDecoration: "none" }}
                to="/sign-in"
                className="formatted-link"
              >
                Or Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
