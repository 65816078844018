import "./App.css";
import { Routes, Route } from "react-router-dom";
import MobileMenu from "./components/mobile-menu/mobile-menu.js";
import DesktopMenu from "./components/desktop-menu/desktop-menu.js";
import HomeOverview from "./pages/home-overview/home-overview.js";
import NotificationCenter from "./pages/notification-center/notification-center.js";
import Deposit from "./pages/deposit/deposit.js";
import Payout from "./pages/payout/payout.js";
import Profile from "./pages/profile/profile.js";
import Statement from "./pages/statement/statement.js";
import PaymentMethod from "./pages/payment-method/payment-method.js";
import SearchBar from "./components/search-bar/search-bar.js";
import SignIn from "./pages/sign-in/sign-in.js";
import ForgotPassword from "./pages/forgot-password/forgot-password.js";
import SignUp from "./pages/sign-up/sign-up.js";
import { useMembersList } from "./api/members-list/use-list-member-list.js";
import useAuth from "./api/auth/use-ensure-cred.js";
import PasswordReset from "./pages/password-reset/password-reset.js";
import PageNotFound from "./pages/page-not-found/page-not-found.js";
import UserActivation from "./pages/activate-user/activate-user.js";
function App() {
  const { data: userData } = useMembersList();
  useAuth();
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <HomeOverview
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/notificationCenter"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <NotificationCenter
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/deposit"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <Deposit
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/payout"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <Payout
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <Profile
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/statement"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <Statement
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/paymentMethod"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <PaymentMethod
                userData={userData && userData.length > 0 ? userData : []}
              />
            </>
          }
        />
        <Route
          exact
          path="/antpool"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu
                userData={userData && userData.length > 0 ? userData : []}
                styleName="desktop"
              />
              <div className="main-content">
                <SearchBar
                  userData={userData && userData.length > 0 ? userData : []}
                  styleName="desktop-search"
                />
                <div className="main-overview">
                  <h1 style={{ textAlign: "center" }} className="title">
                    Coming soon!
                  </h1>
                </div>
              </div>
            </>
          }
        />

        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />

        <Route
          exact
          path="/password_reset/:uid/:token"
          element={<PasswordReset />}
        />
        <Route
          exact
          path="/user_activation/:uid/:token"
          element={<UserActivation />}
        />

        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
