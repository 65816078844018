import './reinject-modal.css';
import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/joy/Divider';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions';
import { useAddTransactions } from '../../api/transactions/use-add-transaction';
import toast from 'react-hot-toast';

function ReinjectModal(props) {

    const [openPayout1, setOpenPayout1] = React.useState(false);
    const [successSnackbar, setsuccessSnackbar] = React.useState(false);
    const truncateToTwoDecimals = (num) => Math.trunc(num * 100) / 100;

    const { mutate: addTransaction, isPending } = useAddTransactions({
        id: props.ID,
        callBackOnSuccess: () => {
            toast.success("Reinjection recorded")
        }
    });

    return (
        <div className="button-left">

            <Button
                disabled={props.disabled}
                color="primary"
                variant="soft"
                size="lg"
                style={{ width: "-webkit-fill-available" }} onClick={() => setOpenPayout1(true)}>Reinject
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openPayout1}
                onClose={() => setOpenPayout1(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Reinject
                    </Typography>
                    <Divider orientation="horizontal" />
                    <Typography
                        level="title-lg" variant="plain"
                    >
                        Are you sure you want to reinject
                        <div className="big-text-blue">
                            {typeof props.value_btc === "number"
                                ? `${props.value_btc.toFixed(5)} BTC`
                                : "Invalid value"}

                        </div>
                        <TermsAndConditions />
                    </Typography>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyItems: "stretch", gap: "10px" }}>
                        <Button
                            size="lg"
                            onClick={() => {
                                addTransaction({
                                    "owner": props.ID,
                                    "trans_type": "CREDIT",
                                    "trans_mode": "CRYPTO",
                                    "trans_reason": "REINJECTION",
                                    "trans_amt_btc": props.value_btc,
                                })
                                setOpenPayout1(false);
                            }}
                        >Yes</Button>
                        <Button
                            size="lg"
                            color="neutral"
                            onClick={() => { setOpenPayout1(false); }}
                        >No</Button>
                    </div>
                </Sheet>
            </Modal>

        </div>

    );
};

export default ReinjectModal;