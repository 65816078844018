import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListDepositsApproved = () => {
  return useReadData({
    queryKey: [["Deposits"]],
    endpoint: API.transactionsDepositApproved,
  });
};

export { useListDepositsApproved };
