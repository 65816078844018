import * as React from "react";
import "./terms-and-conditions.css";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import ModalDialog from "@mui/joy/ModalDialog";
import classnames from "classnames";
import Button from "@mui/joy/Button";

function TermsAndConditions(props) {
  const [openTerms, setOpenTerms] = React.useState(false);

  return (
    <div>
      <a
        className={classnames(props.styleName, "black-ref")}
        onClick={() => setOpenTerms(true)}
      >
        <Typography level="title-lg" variant="plain">
          By clicking {props.text != null ? props.text : "yes"}, you agree to
          the <span style={{ textDecoration: "underline" }}>Terms and Conditions</span>
        </Typography>
      </a>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openTerms}
        onClose={() => setOpenTerms(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog layout="fullscreen">
          <ModalClose />

          <Typography textColor="black" color="neutral" level="h1">
            Terms and Conditions
          </Typography>
          <Divider />
          <iframe
            src="../../../terms_and_conditions.pdf"
            title="Terms and Conditions"
            style={{ width: "100%", height: "100vh", border: "none" }}
          />
          <Divider />
          <Button color="danger" onClick={() => setOpenTerms(false)}>
            Close
          </Button>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default TermsAndConditions;
