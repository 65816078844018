import "./password-reset.css";
import Input from "@mui/joy/Input";
import Logo from "../../components/Logo/logo";
import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useResetPassword } from "../../api/users/use-reset-password";
import { useState } from "react";

function PasswordReset() {
  const navigate = useNavigate(); // Get the navigate function
  const [new_password, setNewPassword] = useState("");
  const [re_new_password, setReNewPassword] = useState("");
  const { uid, token } = useParams();
  const { mutate: updatePassword, isPending } = useResetPassword({
    callBackOnSuccess: () => {
      toast.success("request sent");

      navigate("/sign-in");
    },
  });
  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          <Logo />
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>
            Forgot password?
          </div>
        </div>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            formData.append("token", token);
            formData.append("uid", uid);
            updatePassword(formData);
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div>Enter new password</div>
            {new_password.length < 8 && new_password !== " " ? (
              <p style={{ color: "#FF91A4" }}>
                Password must contain at least 8 characters
              </p>
            ) : (
              " "
            )}
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              name="new_password"
              type="password"
              onChange={(e) => { setNewPassword(e.target.value) }}
            />
            <div>Re-enter new password</div>
            {new_password !== re_new_password && re_new_password != "" ? (
              <p style={{ color: "#FF91A4" }}>
                Passwords don't match
              </p>
            ) : (
              " "
            )}
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              name="re_new_password"
              type="password"
              onChange={(e) => { setReNewPassword(e.target.value) }}
            />

            <br></br>
            <Button
              style={{
                padding: "10px 25px",
                marginLeft: "auto",
                fontSize: "1.2rem",
              }}
              type="submit"
              disabled={re_new_password == new_password && new_password.length >= 8 ? false : true}
            >
              Update password
            </Button>
          </Stack>
        </form>
        <Link
          style={{ textDecoration: "none" }}
          to="/sign-in"
          className="formatted-link"
        >
          Sign in?
        </Link>
      </div>
    </div>
  );
}

export default PasswordReset;
