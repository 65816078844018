import React, { useState, useMemo, useEffect } from "react";
import "./deposit-modal.css";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import TermsAndConditions from "../terms-and-conditions/terms-and-conditions";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

// import { useListTransactions } from '../../api/transactions/use-list-transactions.js';
import { useAddTransactions } from "../../api/transactions/use-add-transaction.js";
import { useMembersList } from "../../api/members-list/use-list-member-list.js";
import toast from "react-hot-toast";

function DepositModal(props) {


    const [userID, setUserID] = useState(props.ID);
    const [openDeposit1, setOpenDeposit1] = React.useState(false);
    const [depositValue, setDepositValue] = React.useState("");
    const [currencyValue, setCurrencyValue] = React.useState("BTC");
    const handleDepositChange = (event) => {
        setDepositValue(event.target.value);
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrencyValue(newValue);
    };
    const [exchangeRate, setExchangeRate] = React.useState("");
    const truncateToTwoDecimals = (num) => Math.trunc(num * 100) / 100;
    const [base64, setBase64] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(",")[1];
                setBase64(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch(
                    "https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT"
                );
                const data = await response.json();
                setExchangeRate(data.price); // Assuming `price` is the key in the response
            } catch (error) {
                console.error("Error fetching exchange rate:", error);
            }
        };

        fetchExchangeRate();
    }, []);

    const validDepositValue = () => {
        if (currencyValue === "USD") {
            return depositValue > 499 && base64 !== "";
        }

        return depositValue > 0 && base64 !== "";
    };

    const { mutate: addTransaction, isPending } = useAddTransactions({
        id: userID,
        callBackOnSuccess: () => { toast.success("Transaction added successfully") },
    });

    const { data: transactions } = useMembersList();
    return (
        <div className="button-left" style={{ marginLeft: "auto", height: "4rem" }}>
            <Button
                onClick={() => setOpenDeposit1(true)}
                variant="soft"
                size="lg"
                startDecorator={<Add />}
            >
                Add deposit
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openDeposit1}
                onClose={() => setOpenDeposit1(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: "md",
                        p: 3,
                        boxShadow: "lg",
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Deposit
                    </Typography>
                    <Divider orientation="horizontal" />
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            const formData = new FormData(event.target);
                            formData.append("trans_reason", "INVESTMENT");
                            formData.append("trans_type", "CREDIT");
                            formData.append("trans_picture", base64);
                            if (currencyValue === "BTC") {
                                formData.append("trans_amt_btc", depositValue)
                            } else {
                                formData.append("trans_amt_usd", depositValue)
                            }
                            console.log(transactions)
                            if (transactions && transactions.length >= 1) {
                                setUserID(transactions[0].id);
                                addTransaction(formData);
                                setOpenDeposit1(false);
                                setBase64("")
                            } else {
                                toast.error("Something went wrong, please try again later.")
                            }
                        }}
                    >
                        <div style={{ fontSize: "20px", padding: "10px 0px" }}>
                            <div>Deposit amount type</div>
                            <Select defaultValue="BTC" onChange={handleCurrencyChange}>
                                <Option value="BTC">BTC</Option>
                                <Option value="USD">USD</Option>
                            </Select>

                            {/* {exchangeRate !=== '' && (
                                <Typography color="primary"> current exchange rate: 1 BTC = {exchangeRate} USD</Typography>
                            )} */}
                            <div>Amount to deposit</div>

                            <Stack spacing={1.5}>
                                <Input
                                    style={{ width: "auto" }}
                                    value={depositValue}
                                    onChange={handleDepositChange}
                                    placeholder="Enter amount"
                                    type="number"
                                    endDecorator={
                                        <React.Fragment>
                                            <Divider orientation="vertical" />
                                            <div style={{ paddingLeft: "10px" }}>{currencyValue}</div>
                                        </React.Fragment>
                                    }
                                    sx={{ width: 300 }}
                                />

                                {depositValue !== "" &&
                                    depositValue > 0 &&
                                    currencyValue === "BTC" && (
                                        <Typography color="primary">
                                            {" "}
                                            {depositValue} BTC ≈{" "}
                                            {truncateToTwoDecimals(exchangeRate * depositValue)} USD
                                        </Typography>
                                    )}

                                {depositValue !== "" &&
                                    depositValue < 500 &&
                                    currencyValue === "USD" && (
                                        <Typography color="danger">Transaction amount cannot be less than 500 USD</Typography>

                                    )}
                                {depositValue !== "" &&
                                    depositValue >= 500 &&
                                    currencyValue === "USD" && (
                                        <Typography color="primary">
                                            {" "}
                                            {depositValue} USD ≈{" "}
                                            {(depositValue / exchangeRate).toFixed(5)} BTC{" "}
                                        </Typography>
                                    )}
                                <div>Deposit mode</div>
                                <Select name="trans_mode" defaultValue="BANK">
                                    <Option value="BANK">Bank</Option>
                                    <Option value="CRYPTO">Crypto</Option>
                                </Select>
                                <div>Proof of payment </div>
                                <Input
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <p style={{ fontSize: "15px", marginTop: 0 }}>please upload a PNG, JPEG, or JPG file.</p>
                                <div>Note</div>
                                <Input placeholder="Optional Note" name="note" />
                                <TermsAndConditions text="'Confirm'" />
                                <Button disabled={!validDepositValue()} size="lg" type="submit">
                                    Confirm
                                </Button>
                            </Stack>
                        </div>
                    </form>
                </Sheet>
            </Modal>
        </div>
    );
}

export default DepositModal;
