import "./activate-user.css";
import Logo from "../../components/Logo/logo";
import { useParams } from "react-router-dom";
import { useActivateUser } from "../../api/users/use-activate-user";

const Good = () => <div>User is now active.</div>;
const Mild = () => <div>User already active.</div>;
const Bad = () => <div>Invalid token.</div>;

function UserActivation() {
  const { uid, token } = useParams();
  const { data: activateUser } = useActivateUser(uid, token);

  let content;

  if (activateUser?.response.detail === "Stale token for given user.") {
    content = <Mild />
  }
  else {
    if (activateUser?.response.token?.length > 0) {
      content = <Bad />
    }
    else {
      content = <Good />
    }
  }
  return (
    <div className="main-signin" >
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", padding: "25px", borderRadius: "12px" }}>
        <Logo />
        <div style={{ fontSize: "3rem", fontWeight: "bold" }}>
          {content}
        </div>
      </div>
    </div>
  );
}

export default UserActivation;
