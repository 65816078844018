import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";

export const useAddTransactions = ({ callBackOnSuccess }) =>
  usePostData({
    endpoint: API.transactions,
    queryKeysToInvalidate: [["transactions"]],
    callBackOnSuccess,
    hideSuccessToast: true
  });
