import './payment-method.css';
import SearchBar from '../../components/search-bar/search-bar.js';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import React, { useState, useMemo } from 'react';
import { useMembersList } from "../../api/members-list/use-list-member-list.js";
import { useEditMemberList } from "../../api/members-list/use-edit-member-list.js";
import toast from 'react-hot-toast';

function PaymentMethod(props) {
    const [openBankPayment, setOpenBankPayment] = React.useState(false);
    const [openCryptoPayment, setOpenCryptoPayment] = React.useState(false);
    const [successSnackbar, setsuccessSnackbar] = React.useState(false);
    const [userID, setUserID] = useState(-1);

    const crypto_networks = {
        "USDT": ["Tron (TRC20)", "BNB Smart Chain (BEP20)", "Ethereum (ERC20)"],
        "BTC": ["Bitcoin", "BNB Smart Chain (BEP20)", "Ethereum (ERC20)"],
        "USDC": ["BNB Smart Chain (BEP20)", "Solana", "Ethereum (ERC20)"]
    }
    const crypto_list = ["BTC", "USDT", "USDC"]
    let crypto_list_components = crypto_list.map((item) => (
        <Option value={item}>{item}</Option>
    ));
    const third_crypto_option = <Option value="BTC">Bitcoin (BTC)</Option>;


    const { data: userData } = useMembersList();
    const { mutate: updateUser, isPending2 } = useEditMemberList({
        id: userID,
        callBackOnSuccess: () => {
            window.location.reload();
            toast.success("Changes registered")
        }
    });
    return (
        <div className="main-content">

            <SearchBar userData={props.userData && props.userData.length > 0 ? props.userData : []} styleName="desktop-search" />
            <div className="main-overview">
                <div style={{ display: "flex", alignItems: "center", margin: "0.7rem 0rem" }}>
                    <h1 className="title">Payment method</h1>
                </div>
                <div className="cards one-column ">
                    <div className="card">
                        <div className="subtitle"> Bank details</div>
                        <div className="two-col-grid">
                            <div className="smaller-blue-subtitle">Bank name:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.bank_name) : ("⌛")}</div>

                            <div className="smaller-blue-subtitle">Branch name:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.bank_branch) : ("⌛")}</div>


                            <div className="smaller-blue-subtitle">IBAN:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.bank_iban) : ("⌛")}</div>

                            <div className="smaller-blue-subtitle">SWIFT code:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.bank_swiftcode) : ("⌛")}</div>
                            <div></div>
                            <Button style={{ marginTop: "0.7rem" }} onClick={() => setOpenBankPayment(true)} variant="soft" size="lg">
                                Edit bank details
                            </Button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="subtitle"> Crypto wallet details</div>
                        <div className="two-col-grid">
                            <div className="smaller-blue-subtitle">Currency:</div>
                            <div>BTC</div>

                            <div className="smaller-blue-subtitle">Wallet address:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.btc_wallet_address) : ("⌛")}</div>


                            <div className="smaller-blue-subtitle">Network:</div>
                            <div>{userData && userData.length > 0 ? (userData[0].profile.btc_wallet_network) : ("⌛")}</div>
                            <div></div>
                            <Button style={{ marginTop: "0.7rem" }} onClick={() => setOpenCryptoPayment(true)} variant="soft" size="lg">
                                Edit wallet details
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
            {/* BANK PAYMENT MODAL*****************************************************************************/}
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openBankPayment}
                onClose={() => setOpenBankPayment(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Payout
                    </Typography>
                    <Divider orientation="horizontal" />

                    <Typography
                        component="h4"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >Enter bank details
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            setUserID(userData[0].id);
                            event.preventDefault();
                            const formData = new FormData(event.target)
                            const requestData = {};
                            formData.forEach((value, key) => {
                                if (key.startsWith('profile.')) {
                                    const profileKey = key.split('.')[1];
                                    requestData.profile = { ...requestData.profile, [profileKey]: value };
                                } else {
                                    requestData[key] = value;
                                }
                            });

                            updateUser(requestData);
                            setOpenBankPayment(false);
                        }}
                    >
                        <Stack spacing={1}>
                            <Input placeholder="Bank name" required name="profile.bank_name" />
                            <Input placeholder="Branch name" required name="profile.bank_branch" />
                            <Input placeholder="IBAN" required name="profile.bank_iban" />
                            <Input placeholder="Swift" required name="profile.bank_swiftcode" />
                            <Button
                                size="lg"
                                type="submit"
                            >Submit</Button>
                        </Stack>
                    </form>
                </Sheet>
            </Modal>
            <Snackbar
                size="lg"
                variant="soft"
                color="success"
                open={successSnackbar}
                onClose={() => setsuccessSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                endDecorator={
                    <Button
                        onClick={() => setsuccessSnackbar(false)}
                        size="lg"
                        variant="soft"
                        color="success"
                    >
                        Dismiss
                    </Button>
                }
            >
                Your request has been recorded.
            </Snackbar>
            {/* CRYPTO PAYMENT MODAL*****************************************************************************/}
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openCryptoPayment}
                onClose={() => setOpenCryptoPayment(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        minWidth: { xs: 100, md: 400 },
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        level="h2"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Crypto Wallet
                    </Typography>
                    <Divider orientation="horizontal" />

                    <Typography
                        component="h4"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >Enter wallet details
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            setUserID(userData[0].id);
                            event.preventDefault();
                            const formData = new FormData(event.target)
                            const requestData = {};
                            formData.forEach((value, key) => {
                                if (key.startsWith('profile.')) {
                                    const profileKey = key.split('.')[1];
                                    requestData.profile = { ...requestData.profile, [profileKey]: value };
                                } else {
                                    requestData[key] = value;
                                }
                            });

                            updateUser(requestData);
                            setOpenCryptoPayment(false);
                        }}
                    >
                        <Stack spacing={1}>
                            {/* Select currency
                        <Select defaultValue="BTC"
                            onChange={()=>{}} >
                            {crypto_list_components}
                        </Select>    */}
                            Enter BTC wallet address
                            <Input placeholder="Wallet address" required name="profile.btc_wallet_address" />
                            Select Network
                            <Select defaultValue="BEP20"
                                name="profile.btc_wallet_network" >
                                <Option value="BEP20">BNB Smart Chain (BEP20)</Option>
                                <Option value="ERC20">Ethereum (ERC20)</Option>
                                {third_crypto_option}
                            </Select>
                            <Button
                                size="lg"
                                type="submit">Submit</Button>
                        </Stack>
                    </form>
                </Sheet>
            </Modal>
        </div>
    );
}


export default PaymentMethod;